import { Constants, StudentMonitor, WebsocketBrowser } from '@derolfgroep/student-monitor-client'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useCurrentUser from '../../api/queries/currentUser/useCurrentUser'
import useCurrentUserSchool from '../../api/queries/currentUser/useCurrentUserSchool'
import { MODULE_STUDENT_MONITOR } from '../../constants'
import { RemoteViewContext, useExtensionInstalledContext } from '../../context/app/app'
import { addOnlineStudent, removeOnlineStudent } from '../../redux/groups/onlineStudentReducer'
import { newScreenshotUrl } from '../../redux/groups/screenshotUrlReducer'
import { newTabInfo } from '../../redux/groups/tabInfoReducer'
import { getSessionAccessToken } from '../../utils/auth'
import useSendExtensionMessage from './useSendExtensionMessage'
import useStudentMonitorEnabled from './useStudentMonitorEnabled'
import useWebsocketLoggedIn from './useWebsocketLoggedIn'

const RemoteViewProvider = ({ children }) => {
  const extensionIsInstalled = useExtensionInstalledContext()
  const { isEmployee, moduleData, id: currentUserId } = useCurrentUser()
  const { currentSchool } = useCurrentUserSchool()
  const isStudentMonitorEnabled = useStudentMonitorEnabled()
  const token = getSessionAccessToken()
  const hasToken = token !== null
  const dispatch = useDispatch()
  const { rest_url, websocket_url } = (moduleData ?? {})[MODULE_STUDENT_MONITOR] ?? {}

  const [studentMonitor, setStudentMonitor] = useState(null)

  const { setLoggedIn } = useWebsocketLoggedIn()
  const sendExtensionMessage = useSendExtensionMessage()
  // const { enqueueSnackbar } = useSnackbar()

  /**
   * Websocket should connect if:
   *  - Student monitor is enabled
   *  - User is employee OR has extension installed
   */
  const shouldConnect = useMemo(
    () =>
      hasToken &&
      isStudentMonitorEnabled &&
      (isEmployee || extensionIsInstalled) &&
      rest_url !== undefined &&
      websocket_url !== undefined &&
      !window.Cypress,
    [hasToken, isStudentMonitorEnabled, isEmployee, extensionIsInstalled, rest_url, websocket_url]
  )

  useEffect(() => {
    if (!shouldConnect) {
      return () => null
    }

    const client = isEmployee
      ? new StudentMonitor(token, currentSchool.id).asTeacher()
      : new StudentMonitor(token, currentSchool.id).asStudent()

    client.withGateway(new WebsocketBrowser(), websocket_url)

    if (isEmployee) {
      client.withRest(rest_url)

      client.gateway.on(Constants.ACTION_NEW_SCREENSHOT, (message) => {
        dispatch(newScreenshotUrl(message))
      })

      client.gateway.on(Constants.ACTION_TAB_INFO, (message) => {
        dispatch(newTabInfo(message))
      })
    } else {
      client.gateway.onAll((event) => {
        if (event.args.length <= 0) {
          return
        }

        const message =
          event.event === Constants.ACTION_LOGIN
            ? {
                accessToken: token,
                portalUrl: process.env.PORTAL_URL,
                userId: currentUserId,
                ...event.args[0],
              }
            : event.args[0]

        sendExtensionMessage(message)
      })
    }

    client.gateway.on('error', (error) => {
      Sentry.captureEvent(error)
    })

    setStudentMonitor(client)

    client.gateway.on(Constants.ACTION_LOGIN, () => {
      setLoggedIn(true)
      // TODO: Uncomment when underlying problem is resolved
      // enqueueSnackbar('Verbonden met de Meekijken service', { variant: 'success' })
    })

    client.gateway.on(Constants.ACTION_ALERT_STUDENT_ONLINE, (message) => {
      dispatch(addOnlineStudent(message))
    })

    client.gateway.on(Constants.ACTION_ALERT_STUDENT_OFFLINE, (message) => {
      dispatch(removeOnlineStudent(message))
    })

    client.gateway.connect()

    return () => {
      client.gateway.close()
      client.gateway.removeAllListeners()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isEmployee, shouldConnect, dispatch, setLoggedIn])

  const value = {
    studentMonitor,
  }

  return <RemoteViewContext.Provider value={value}>{children}</RemoteViewContext.Provider>
}

RemoteViewProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

RemoteViewContext.Provider.propTypes = {
  value: PropTypes.shape({
    studentMonitor: PropTypes.shape({
      gateway: PropTypes.object,
      rest: PropTypes.object,
    }),
  }),
}

export default RemoteViewProvider
