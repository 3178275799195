// Some random contants. If growing of the same type, please create a new file and reference it in index.js

export const DEFAULT = 'DEFAULT'

export const RELATION_TYPE_OWNER = 'OWNER'

export const FAILED_URL_CONSTRUCTOR = 'https://_'

export const DEFAULT_ENDPOINT_SNACKBAR_ERROR_TEXT = 'Fout bij het ophalen van gegevens'

export const SUCCESS = 'success'
export const ERROR = 'error'

export const GET = 'GET'
export const POST = 'POST'
export const PATCH = 'PATCH'
export const DELETE = 'DELETE'
export const HEAD = 'HEAD'
export const PUT = 'PUT'

export const PUBLIC = 'PUBLIC'
export const PRIVATE = 'PRIVATE'

export const SHOW = 'SHOW'
export const HIDE = 'HIDE'

export const ADD = 'ADD'
export const EDIT = 'EDIT'

export const REDIRECT_TO = 'redirectTo'

export const USER_AGENT_CHROME_OS = 'CrOS'

export const GOOGLE_CREDENTIAL_API_URL = 'https://ssl.gstatic.com/accounts/chrome/users-1.0.js'

export const SQUARE = 'square'
export const ROUNDED = 'rounded'
export const CIRCULAR = 'circular'

export const SMALL = 'small'
export const MEDIUM = 'medium'
export const LARGE = 'large'

export const ALWAYS = 'always'
