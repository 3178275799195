import { alpha, createTheme } from '@mui/material'
import { baseApp as app, baseColors as colors, baseComponents as components } from './themevars'

const theme = createTheme({
  spacing: (factor) => `${0.5 * factor}rem`,
})

export const baseTheme = createTheme({
  spacing: theme.spacing,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1680,
    },
  },
  palette: {
    common: {
      white: colors.common.white,
      black: colors.common.black,
      gray: colors.common.gray,
    },
    error: {
      light: colors.error.light,
      main: colors.error.main,
      dark: colors.error.dark,
    },
    warning: {
      light: colors.warning.light,
      main: colors.warning.main,
      dark: colors.warning.dark,
    },
    info: {
      light: colors.info.light,
      main: colors.info.main,
      dark: colors.info.dark,
    },
    success: {
      light: colors.success.light,
      main: colors.success.main,
      dark: colors.success.dark,
    },
    none: {
      main: alpha(colors.common.white, 0),
    },
    appBackground: {
      main: colors.appBackground.main,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
        h1: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(24)}, 
            ${app.scaleFactor.large}, 
            ${theme.typography.pxToRem(34)}
          )`,
        },
        h2: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(18)},
            ${app.scaleFactor.large},
            ${theme.typography.pxToRem(22)}
          )`,
        },
        h3: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(16)}, 
            ${app.scaleFactor.large},
            ${theme.typography.pxToRem(18)}
          )`,
        },
        h4: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(14)},
            ${app.scaleFactor.large},
            ${theme.typography.pxToRem(16)}
          )`,
        },
        h5: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(14)},
            ${app.scaleFactor.large},
            ${theme.typography.pxToRem(16)}
          )`,
        },
        h6: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.large},
            ${theme.typography.pxToRem(13)}
          )`,
        },
        subtitle1: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(14)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(16)}
          )`,
        },
        subtitle2: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(14)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(18)}
          )`,
        },
        subtitle3: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(10)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(11)}
          )`,
        },
        body1: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)},
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
        },
        body2: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(13)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(15)}
          )`,
        },
        button: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
          textTransform: 'none',
        },
        caption: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(10)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(12)}
          )`,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'always',
      },
      styleOverrides: {
        root: {
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'none',
          },
          '&:focus': {
            outline: 'none',
            boxShadow: app.input.focus.boxShadow,
          },
          fontSize: `clamp(
            ${theme.typography.pxToRem(13)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(15)}
          )`,
        },
        button: {
          outline: 'none !important',
        },
        underlineHover: {
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
        underlineNone: {
          textDecoration: 'none',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.pxToRem(24),
        },
        fontSizeSmall: {
          fontSize: theme.typography.pxToRem(16),
        },
        fontSizeLarge: {
          fontSize: theme.typography.pxToRem(36),
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.pxToRem(24),
        },
        fontSizeSmall: {
          fontSize: theme.typography.pxToRem(16),
        },
        fontSizeLarge: {
          fontSize: theme.typography.pxToRem(36),
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          transition: theme.transitions.create(
            ['color', 'border-color', 'background-color', 'box-shadow', 'transform', 'opacity'],
            {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.standard,
            }
          ),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: theme.typography.fontWeightMedium,
          textTransform: 'none',
          minWidth: 'unset',
          '&:focus': {
            boxShadow: app.input.focus.boxShadow,
          },
          '&:disabled': {
            cursor: 'not-allowed',
          },
          gap: theme.spacing(1),
        },
        startIcon: {
          margin: 0,
        },
        endIcon: {
          margin: 0,
        },
        sizeSmall: {
          padding: theme.spacing(0.25, 0.75),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0.5, 1),
          },
          fontSize: `clamp(
            ${theme.typography.pxToRem(10)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(12)}
          )`,
        },
        sizeMedium: {
          padding: theme.spacing(0.75, 1),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1, 2),
          },
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
        },
        sizeLarge: {
          padding: theme.spacing(0.75, 1.5),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2, 3),
          },
          fontSize: `clamp(
            ${theme.typography.pxToRem(14)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(15)}
          )`,
        },
        sizeApplogo: {
          padding: theme.spacing(0.75, 1.5),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1, 2),
          },
          fontSize: `clamp(
            ${theme.typography.pxToRem(14)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(16)}
          )`,
          fontWeight: theme.typography.fontWeightBold,
          color: colors.success.dark,
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: app.input.focus.boxShadow,
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          transition: theme.transitions.create(
            ['color', 'border-color', 'background-color', 'box-shadow', 'transform', 'opacity'],
            {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.standard,
            }
          ),
          '&:focus': {
            boxShadow: app.input.focus.boxShadow,
          },
          '&:disabled': {
            cursor: 'not-allowed',
          },
        },
        sizeSmall: {
          padding: theme.spacing(0.25),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0.5),
          },
        },
        sizeMedium: {
          padding: theme.spacing(0.5),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1),
          },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontWeight: theme.typography.fontWeightMedium,
          lineHeight: 1.45,
          textTransform: 'none',
          '&:focus': {
            boxShadow: app.input.focus.boxShadow,
          },
          '&:disabled': {
            cursor: 'not-allowed',
          },
        },
        sizeSmall: {
          padding: theme.spacing(0.25, 0.75),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0.5, 1),
          },
          fontSize: `clamp(
            ${theme.typography.pxToRem(10)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(12)}
          )`,
        },
        sizeMedium: {
          padding: theme.spacing(0.75, 1),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1, 2),
          },
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
        },
        sizeLarge: {
          padding: theme.spacing(0.75, 1.5),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2, 3),
          },
          fontSize: `clamp(
            ${theme.typography.pxToRem(14)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(16)}
          )`,
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
      },
      styleOverrides: {
        marginDense: {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(0.5),
        },
        marginNormal: {
          marginTop: theme.spacing(1.5),
          marginBottom: theme.spacing(1),
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(-1),
          marginRight: theme.spacing(1),
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(0.5),
          fontSize: `clamp(
            ${theme.typography.pxToRem(10)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(12)}
          )`,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          '&.passive': {
            '& .MuiInputBase-root': {
              borderColor: 'transparent',
              backgroundColor: 'transparent',
            },
            '& .MuiInputBase-input[disabled]': {
              cursor: 'default',
              color: theme.palette.text.primary,
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
          transition: theme.transitions.create(
            ['color', 'border-color', 'background-color', 'box-shadow', 'transform'],
            {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.standard,
            }
          ),
          '&.Mui-error': {
            backgroundColor: alpha(colors.error.main, 0.075),
          },
        },
        input: {
          padding: theme.spacing(0.75, 1),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1, 1.25),
          },
          borderRadius: 'inherit',
          height: 'auto',
          textOverflow: 'ellipsis',
          '&:focus': {
            boxShadow: app.input.focus.boxShadow,
          },
          '&[disabled]': {
            cursor: 'not-allowed',
            WebkitTextFillColor: 'unset',
          },
          '&::placeholder': {
            fontWeight: theme.typography.fontWeightMedium,
            opacity: '1',
          },
        },
        formControl: {
          'label + &': {
            marginTop: 0,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
        },
        positionStart: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(-1),
        },
        positionEnd: {
          marginLeft: theme.spacing(-1),
          marginRight: theme.spacing(1),
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
        disableAnimation: true,
        variant: 'standard',
      },
      styleOverrides: {
        aside: {
          marginBottom: 0,
        },
        shrink: {
          marginBottom: theme.spacing(0.5),
          top: 'auto',
          left: 'auto',
          transform: 'none',
          position: 'relative',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        icon: {
          marginRight: theme.spacing(1),
        },
        select: {
          padding: theme.spacing(0.75, 1),
          minHeight: 'auto',
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1, 1.25),
          },
          '&:focus': {
            borderRadius: 'inherit',
            boxShadow: app.input.focus.boxShadow,
          },
          '&.MuiSelect-select': {
            paddingRight: theme.spacing(4),
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: theme.spacing(0.25),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0.5),
          },
          '& .MuiInput-input': {
            padding: theme.spacing(0.25),
            [theme.breakpoints.up('md')]: {
              padding: theme.spacing(0.5),
            },
          },
          '& .MuiInputAdornment-positionStart': {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(-0.5),
          },
          '&:focus-within': {
            boxShadow: app.input.focus.boxShadow,
          },
        },
        hasPopupIcon: {
          '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
            paddingRight: theme.spacing(4),
            [theme.breakpoints.up('md')]: {
              paddingRight: theme.spacing(5),
            },
          },
        },
        hasClearIcon: {
          '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
            paddingRight: theme.spacing(4),
            [theme.breakpoints.up('md')]: {
              paddingRight: theme.spacing(5),
            },
          },
          '&.MuiAutocomplete-hasPopupIcon': {
            '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
              paddingRight: theme.spacing(9),
              [theme.breakpoints.up('md')]: {
                paddingRight: theme.spacing(10),
              },
            },
          },
        },
        input: {
          '&:focus': {
            boxShadow: 'none',
          },
        },
        tag: {
          margin: theme.spacing(0.25),
        },
        endAdornment: {
          top: '50%',
          right: theme.spacing(0.5),
          transform: 'translateY(-50%)',
          minWidth: theme.spacing(4),
        },
        popupIndicator: {
          marginRight: 0,
        },
        paper: {
          boxShadow: theme.shadows[6],
        },
        listbox: {
          padding: 0,
        },
        option: {
          paddingTop: theme.spacing(1),
          paddingRight: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(1),
          borderRadius: 'inherit',
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
          minHeight: 'auto',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0.5),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0.75),
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          boxShadow: `0 0 2px 1px ${alpha(colors.common.black, 0.25)}`,
          '.Mui-checked &': {
            boxShadow: `0 0 2px 1px ${alpha(colors.success.dark, 0.25)}`,
            backgroundColor: colors.success.dark,
          },
        },
        track: {
          backgroundColor: colors.common.black,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: theme.spacing(0.5),
        },
        thumb: {
          width: `calc(${theme.spacing(3)} - 4px)`,
          height: `calc(${theme.spacing(3)} - 4px)`,
        },
        track: {
          border: 'none',
        },
        rail: {
          opacity: 0.25,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: `clamp(
            ${theme.spacing(0.75)},
            ${app.scaleFactor.default},
            ${theme.spacing(1.5)}
          )`,
          '&:last-child': {
            paddingBottom: `clamp(
              ${theme.spacing(0.75)},
              ${app.scaleFactor.default},
              ${theme.spacing(1.5)}
            )`,
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: `clamp(
            ${theme.spacing(1)},
            ${app.scaleFactor.default},
            ${theme.spacing(2)}
          )`,
          paddingTop: 0,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        outlined: {
          border: 0,
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
          marginBottom: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0.75, 1),
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
          minHeight: 'auto',
          minWidth: 'auto',
          textTransform: 'none',
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1, 1.5),
            minWidth: 'auto',
          },
        },
        labelIcon: {
          paddingTop: theme.spacing(0.75),
          [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(1),
          },
          minHeight: 'auto',
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          padding: theme.spacing(0, 0.5),
          minWidth: theme.spacing(2.5),
          borderRadius: theme.spacing(1.25),
        },
        dot: {
          height: theme.spacing(1),
          minWidth: theme.spacing(1),
          borderRadius: theme.spacing(0.5),
          bottom: theme.spacing(0.5),
          right: theme.spacing(0.5),
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          padding: theme.spacing(1),
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 6,
      },
      styleOverrides: {
        paper: {
          backgroundColor: alpha(theme.palette.common.white, 0.95),
          backdropFilter: 'blur(3px)',
          '& .MuiListSubheader-root': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          elevation: 0,
        },
      },
      styleOverrides: {
        paper: {
          [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2),
          },
        },
        paperFullWidth: {
          [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${theme.spacing(2)})`,
          },
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        component: 'div',
      },
      styleOverrides: {
        root: {
          padding: `clamp(
            ${theme.spacing(1)},
            ${app.scaleFactor.default},
            ${theme.spacing(2)}
          )`,
          color: 'unset',
          fontFamily: 'unset',
          fontWeight: 'unset',
          fontSize: 'unset',
          lineHeight: 'unset',
          letterSpacing: 'unset',
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 'unset',
          paddingLeft: `clamp(
            ${theme.spacing(1)},
            ${app.scaleFactor.default},
            ${theme.spacing(2)}
          )`,
          paddingRight: `clamp(
            ${theme.spacing(1)},
            ${app.scaleFactor.default},
            ${theme.spacing(2)}
          )`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: `clamp(
            ${theme.spacing(1)},
            ${app.scaleFactor.default},
            ${theme.spacing(2)}
          )`,
        },
        spacing: {
          '& > :not(:first-of-type)': {
            marginLeft: `clamp(
              ${theme.spacing(0.5)},
              ${app.scaleFactor.default},
              ${theme.spacing(1)}
            )`,
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rectangular: {
          borderRadius: app.widget.borderRadius,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: `clamp(
            ${theme.spacing(1)},
            ${app.scaleFactor.default},
            ${theme.spacing(2)}
          )`,
          borderRadius: app.input.borderRadius,
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
        },
        icon: {
          marginRight: `clamp(
            ${theme.spacing(1)},
            ${app.scaleFactor.default},
            ${theme.spacing(2)}
          )`,
          padding: 0,
          fontSize: 24,
          opacity: 1,
        },
        message: {
          padding: 0,
          flexGrow: 1,
          alignSelf: 'center',
        },
        action: {
          marginLeft: `clamp(
            ${theme.spacing(1)},
            ${app.scaleFactor.default},
            ${theme.spacing(2)}
          )`,
          marginRight: 0,
          paddingLeft: 0,
          paddingTop: 0,
          alignItems: 'baseline',
        },
        standardError: {
          color: colors.error.dark,
          backgroundColor: alpha(colors.error.main, 0.075),
        },
        standardWarning: {
          color: colors.warning.dark,
          backgroundColor: alpha(colors.warning.main, 0.075),
        },
        standardInfo: {
          color: colors.info.dark,
          backgroundColor: alpha(colors.info.main, 0.075),
        },
        standardSuccess: {
          color: colors.success.dark,
          backgroundColor: alpha(colors.success.main, 0.075),
        },
        filledError: {
          backgroundColor: colors.error.main,
        },
        filledWarning: {
          backgroundColor: colors.warning.main,
        },
        filledInfo: {
          backgroundColor: colors.info.main,
        },
        filledSuccess: {
          backgroundColor: colors.success.main,
        },
        outlinedError: {
          color: colors.error.main,
          borderWidth: theme.spacing(0.25),
          borderColor: alpha(colors.error.main, 0.25),
        },
        outlinedWarning: {
          color: colors.warning.main,
          borderWidth: theme.spacing(0.25),
          borderColor: alpha(colors.warning.main, 0.25),
        },
        outlinedInfo: {
          color: colors.info.main,
          borderWidth: theme.spacing(0.25),
          borderColor: alpha(colors.info.main, 0.25),
        },
        outlinedSuccess: {
          color: colors.success.main,
          borderWidth: theme.spacing(0.25),
          borderColor: alpha(colors.success.main, 0.25),
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: theme.spacing(1),
          fontSize: 'inherit',
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(12)},
            ${app.scaleFactor.default},
            ${theme.typography.pxToRem(13)}
          )`,
          fontWeight: theme.typography.fontWeightMedium,
          width: theme.spacing(4),
          height: theme.spacing(4),
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        thickness: 6,
        size: theme.spacing(2),
      },
      styleOverrides: {
        circle: {
          strokeLinecap: 'round',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 4,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          color: colors.success.main,
          fontWeight: theme.typography.fontWeightBold,
          fontSize: theme.typography.pxToRem(18),
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        inset: {
          marginLeft: theme.spacing(6.5),
        },
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
      styleOverrides: {
        padding: {
          paddingTop: theme.spacing(0.75),
          paddingBottom: theme.spacing(0.75),
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          fontSize: `clamp(
            ${theme.typography.pxToRem(10)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(12)}
          )`,
          lineHeight: 'unset',
          margin: theme.spacing(1, 0, 0, 2),
        },
        gutters: {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        padding: {
          paddingTop: theme.spacing(0.75),
          paddingBottom: theme.spacing(0.75),
        },
        gutters: {
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
        },
        button: {
          padding: theme.spacing(0.75, 1),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1, 1.5),
          },
          borderRadius: app.button.borderRadius,
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
          transition: theme.transitions.create(
            ['color', 'border-color', 'background-color', 'box-shadow', 'transform'],
            {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.standard,
            }
          ),
          '&:focus': {
            boxShadow: app.input.focus.boxShadow,
          },
        },
        secondaryAction: {
          paddingRight: theme.spacing(7),
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0.75, 1),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1, 1.5),
          },
          borderRadius: app.button.borderRadius,
          transition: theme.transitions.create(
            ['color', 'border-color', 'background-color', 'box-shadow', 'transform'],
            {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.standard,
            }
          ),
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto !important',
          marginRight: `clamp(
            ${theme.spacing(1)},
            ${app.scaleFactor.default},
            ${theme.spacing(2)}
          )`,
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(0.5),
          marginBottom: theme.spacing(0.5),
        },
        secondary: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(10)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(12)}
          )`,
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          right: theme.spacing(1.5),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0.75, 1),
          borderRadius: app.button.borderRadius,
          minHeight: 'unset',
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1.25, 3),
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: theme.spacing(1, 0),
          },
          '&$disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderRadius: 'inherit',
          minHeight: 'auto',
          '&:focus': {
            boxShadow: app.input.focus.boxShadow,
          },
          '&.Mui-expanded': {
            minHeight: 'auto',
          },
        },
        content: {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
        expandIconWrapper: {
          color: 'inherit',
        },
        expandIcon: {
          transform: 'rotate(-90deg)',
          '&.Mui-expanded': {
            transform: 'rotate(0)',
          },
        },
      },
    },
    MuiGridListTile: {
      styleOverrides: {
        tile: {
          overflow: 'visible',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-of-type .MuiTableCell-root': {
            borderBottomColor: 'transparent',
          },
        },
        hover: {
          transition: theme.transitions.create(['color', 'border-color', 'background-color'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.standard,
          }),
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
          transition: theme.transitions.create(['color', 'border-color', 'background-color'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.standard,
          }),
        },
        head: {
          fontWeight: theme.typography.fontWeightBold,
        },
        sizeSmall: {
          padding: theme.spacing(0.5, 1),
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          color: 'inherit !important',
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
          '& .MuiDataGrid-columnHeader': {
            padding: theme.spacing(0, 1),
          },
        },
        row: {
          transition: theme.transitions.create(
            ['color', 'border-color', 'background-color', 'box-shadow', 'transform', 'opacity'],
            {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.standard,
            }
          ),
          '&:hover, &:focus': {
            '& .contextCell': {
              '& .MuiIconButton-root': {
                opacity: 1,
              },
            },
          },
        },
        cell: {
          padding: theme.spacing(0, 1),
          '&.contextCell': {
            '& .MuiIconButton-root': {
              opacity: 0,
            },
          },
        },
        columnHeaders: {
          '& .MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
          },
          '&:hover': {
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'visible',
            },
          },
        },
        iconButtonContainer: {
          marginLeft: 'auto',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body {
          user-select: none;
          overflow: overlay;
        }

        *::-webkit-scrollbar {
          -webkit-appearance: none;
          width: ${theme.spacing(2.5)};
        }

        *::-webkit-scrollbar-track {
          background-color: ${alpha(theme.palette.common.white, 0)};
        }

        *::-webkit-scrollbar-thumb {
          border-radius: ${theme.spacing(2.5)};
          border: ${theme.spacing(0.75)} solid ${alpha(theme.palette.common.white, 0)};
          background-color: ${alpha(theme.palette.common.black, 0.15)};
          background-clip: content-box;
        }

        *::-webkit-scrollbar-thumb:hover {
          background-color: ${alpha(theme.palette.common.black, 0.25)};
        }

        input[type='search']::-webkit-search-cancel-button {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDggNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTI4LjIyOCwyMy45ODZMNDcuMDkyLDUuMTIyYzEuMTcyLTEuMTcxLDEuMTcyLTMuMDcxLDAtNC4yNDJjLTEuMTcyLTEuMTcyLTMuMDctMS4xNzItNC4yNDIsMEwyMy45ODYsMTkuNzQ0TDUuMTIxLDAuODgNCgkJYy0xLjE3Mi0xLjE3Mi0zLjA3LTEuMTcyLTQuMjQyLDBjLTEuMTcyLDEuMTcxLTEuMTcyLDMuMDcxLDAsNC4yNDJsMTguODY1LDE4Ljg2NEwwLjg3OSw0Mi44NWMtMS4xNzIsMS4xNzEtMS4xNzIsMy4wNzEsMCw0LjI0Mg0KCQlDMS40NjUsNDcuNjc3LDIuMjMzLDQ3Ljk3LDMsNDcuOTdzMS41MzUtMC4yOTMsMi4xMjEtMC44NzlsMTguODY1LTE4Ljg2NEw0Mi44NSw0Ny4wOTFjMC41ODYsMC41ODYsMS4zNTQsMC44NzksMi4xMjEsMC44NzkNCgkJczEuNTM1LTAuMjkzLDIuMTIxLTAuODc5YzEuMTcyLTEuMTcxLDEuMTcyLTMuMDcxLDAtNC4yNDJMMjguMjI4LDIzLjk4NnoiLz4NCjwvc3ZnPg0K");
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          height: 0.875em;
          width: 0.875em;
          cursor: pointer;
          opacity: 0.25;
          transition: opacity 0.15s ease-out;
          -webkit-appearance: none;
          appearance: none;
        },
        
        input[type='search']::-webkit-search-cancel-button:hover {
          opacity: 0.65;
        }
      `,
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fontSize: `clamp(
            ${theme.typography.pxToRem(11)}, 
            ${app.scaleFactor.default}, 
            ${theme.typography.pxToRem(13)}
          )`,
        },
      },
    },
  },
  custom: {
    app: {
      scaleFactor: {
        default: app.scaleFactor.default,
        large: app.scaleFactor.large,
      },
      input: {
        borderRadius: app.input.borderRadius,
        focus: {
          boxShadow: app.input.focus.boxShadow,
        },
      },
      widget: {
        borderRadius: app.widget.borderRadius,
      },
    },
    components: {
      appBar: {
        borderRadius: components.appBar.borderRadius,
        backgroundColor: components.appBar.backgroundColor,
      },
      mainNavigationDrawer: {
        borderRadius: components.mainNavigationDrawer.borderRadius,
        backgroundColor: components.mainNavigationDrawer.backgroundColor,
        open: {
          backgroundColor: components.mainNavigationDrawer.open.backgroundColor,
        },
      },
      ListMainNavigation: {
        color: components.ListMainNavigation.color,
        hover: {
          color: components.ListMainNavigation.hover.color,
          backgroundColor: components.ListMainNavigation.hover.backgroundColor,
        },
        selected: {
          color: components.ListMainNavigation.selected.color,
          backgroundColor: components.ListMainNavigation.selected.backgroundColor,
        },
      },
    },
  },
  typography: {
    fontWeightMediumBold: 600,
  },
})
